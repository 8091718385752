import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFaresPrimary from "../components/metro-fares-primary"

class S20ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="S20"
          description="View route information and buy your ticket for the S20 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">S20 service</h1>
              <p>Ledston Luck - Lady E Hastings School Ledston</p>
              <p>Lady E Hastings School Ledston - Ledston Luck</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="S20 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1gACGuulmI9Zgo-KKvdiOERmay6PSoUA&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Lady Elizabeth Hastings School
                </p>
                <p>
                From Ridge Road, Longdike Lane, High Street, Gibson Lane, Leeds Road, Cross Hills, Butt Hill, Station Road, Berry Lane, Preston Lane, Leeds Road, Park Lane, Newton Lane, Ledston Mill Lane, Main Street, Green Lane.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="S20 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1UUsrrtUzWwzE6cbk4VZ38VlWOFEg6zU&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Lady Elizabeth Hastings School
                </p>
                <p>
                From Green Lane, Ledston Mill Lane, Back Newton Lane, Barnsdale Road, Park Lane, Leeds Road, Preston Lane, Berry Lane, Station Road, Butt Hill, Cross Hills, Leeds Road, Gibson Lane, High Street, Longdike Lane, Ridge Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ledston Luck</td>
                      <td>DEP</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Berry Lane near Cricket Ground</td>
                      <td>DEP</td>
                      <td>0805</td>
                    </tr>
                    <tr>
                      <td>Preston Lane - Great Preston</td>
                      <td>DEP</td>
                      <td>0815</td>
                    </tr>
                    <tr>
                      <td>Lady E Hastings CE School</td>
                      <td>ARR</td>
                      <td>0835</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Lady E Hastings CE School</td>
                      <td>DEP</td>
                      <td>1520</td>
                    </tr>
                    <tr>
                      <td>Preston Lane - Great Preston</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>Berry Lane near Cricket Ground</td>
                      <td>DEP</td>
                      <td>1540</td>
                    </tr>
                    <tr>
                      <td>Ledston Luck</td>
                      <td>ARR</td>
                      <td>1545</td>
                    </tr>
                  </tbody>
                </table>

                <h2 style={{ margin: '0 0 20px 0'}}>24/25 fares</h2>
                <p>Payment options on this service:</p>
                <ul>
                  <li><strong>Cash/contactless</strong>. The fare is £1.50 each way or £15 for a weekly ticket. Try to bring the exact change. Passengers <strong>MUST</strong> be registered on this service to travel. Please apply via the metro website: <a href="https://www.wymetro.com/schools/applications/mybus-primary-school/primary-school-application-forms/">Application form</a></li>
                  <li>You can speak with the school transport team at Metro by calling 0113 348 1122.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default S20ServicePage
